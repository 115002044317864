import jwt_decode from 'jwt-decode';

import api from '../api/caps';
import { hmac } from './hmac';
//import validators from '../services/validators';
import store from '../store';
import config from '../config';
import * as userActions from '../store/actions/userActions';

const tokenName = 'user_token';
const tokenState = 'user_state';
//const consts = window.config.consts;

/**
 * service interaction with user api
 *
 * @class UserService
 */
class UserService {
  constructor() {
    this.state = {};
    this.settings = {};
    this.clientMenu = null;
    this.rights = {
      orders: true,
      validation: true,
    };
  }

  isTokenExists() {
    return localStorage.getItem(tokenName) ? true : false;
  }

  isNeedChangePassword() {
    const state = this.getState();
    return this.isTokenExists() && state.required_reset;
  }

  getClientId() {
    if (!this.isTokenExists()) {
      return false;
    }
    const token = this.getToken();
    let out;
    try {
      out = jwt_decode(token);
    } catch (error) {
      return '';
    }
    return out.client_id;
  }

  getState() {
    let out = this.getToken(tokenState);
    try {
      out = JSON.parse(out);
    } catch (error) {
      //console.error(error, out);
      out = {};
      this.setState(out);
    }
    return out;
  }

  setState(state) {
    this.setToken(tokenState, JSON.stringify(state));
  }

  setToken(name, val) {
    localStorage.setItem(name, val);
    this.state[name] = val;
  }

  getToken(name = tokenName) {
    if (!this.state[name]) {
      this.state[name] = localStorage.getItem(name) ?? '';
      if (!this.state[name] || this.state[name] === 'undefined') {
        this.state[name] = '';
      }
    }
    return this.state[name];
  }

  /**
   * set user to local
   *
   * @param {*} token
   * @memberof User
   */
  setUser(result) {
    if (result.required_reset === undefined) {
      result.required_reset = false;
    }
    this.logined = true;
    this.state[tokenName] = result.token;
    this.state.admin = result.admin;
    localStorage.setItem(tokenName, result.token);
    localStorage.setItem(tokenState, JSON.stringify({ required_reset: result.required_reset, admin: result.admin }));
  }

  /**
   * unset user
   */
  unsetUser() {
    this.state[tokenName] = '';
    localStorage.removeItem(tokenName);
    localStorage.removeItem(tokenState);
  }

  /**
   * Check user token
   *
   * @memberof User
   */
  async checkToken() {
    //console.log('🚀 ~ checkToken ');
    let logined = false;
    let required_reset = false;

    if (this.isTokenExists()) {
      // token exists - check for it valid
      const state = this.getState();
      let badToken = false;
      if (state.admin) {
        await this.runCheckTokenAdmin().catch((__err) => {
          if (Object.values(__err).includes('order.view')) {
            badToken = true;
          }
        });
      } else {
        await this.runCheckToken().catch((__err) => {
          badToken = true;
        });
      }

      if (!badToken) {
        required_reset = state.required_reset;
        logined = required_reset !== undefined && !required_reset; // && state.required_reset !== undefined;
        this.state.admin = state.admin;
      }

      // get info
      if (logined) {
        await this.getAdditionalInfo();
      }
    }
    

    store.dispatch(userActions.checkUser({ logined, admin: this.state.admin }));
    this.logined = logined;
    if (!logined) {
      //if (!required_reset) {
      this.unsetUser();
      //}
    } //else {
    //const token = jwt_decode(this.getToken());
    //console.log(token);
    //}
  }

  /**
   * check token
   * @returns
   */
  async runCheckToken() {
    const clientId = this.getClientId();
    if (!clientId) {
      return Promise.reject();
    }
    return await api.call('clients.view', { id: clientId });
  }

  /**
   * check token admin
   * @returns
   */
  async runCheckTokenAdmin() {
    return await api.call('order.view', { id: '123' });
  }

  /**
   * Login request
   * @param {*} username
   * @param {*} password
   * @returns promise
   */
  async login({ username, password }) {
    const result = await api.call('user.login', { username, password }); /*.catch((error) => {
      console.log('🚀 ~ login ~ error:', error);
      throw new Error(error.message());
    });*/
    // save token and data
    result.admin = result.is_admin === true;
    if (result.admin) {
      result.required_reset = false;
    }

    this.setUser(result);
    // save to store
    store.dispatch(userActions.setUser(result));
    return result;
  }

  /**
   * logout
   */
  logout() {
    store.dispatch(userActions.unsetUser());
    window.continueLink = '';
    window.continueLinkAfterLogout = true;
    this.unsetUser();
  }

  /**
   * change password
   * @param {*} data
   * @returns
   */
  async changePassword(data) {
    const password = hmac(data.password);
    const result = await api.call('users.update_password', { password }).then((data) => {
      localStorage.setItem(tokenState, JSON.stringify({ required_reset: false }));
      return data;
    });

    return result;
  }

  /**
   * get dictionaries
   */
  async getDictionaries() {
    // const result = await api.call('dictionaries.list', {});
    // store.dispatch(userActions.setDictionaries(result));
    const clientId = this.getClientId();
    const result = await api.call('settings.view', {});
    this.settings = result;
    const data = clientId
      ? await api.call('clients.get_zones', { client_id: clientId })
      : { zones: [], orders: true, validation: true };
    const zones = data.zones;
    this.rights = {
      orders: data.orders === undefined ? true : data.orders,
      validation: data.validation === undefined ? true : data.validation,
    };
    console.log('🚀 ~ getDictionaries ~ rights:', this.rights);
    // console.log('🚀 ~ getDictionaries ~ zones:', zones);
    this.clientMenu = config.clientMenu.filter((el) => {
      if (el.test) {
        if (el.test === 'orders' && !data.orders) {
          return false;
        }
        if (el.test === 'validation' && !data.validation) {
          return false;
        }
      }

      return true;
    });

    this.zones = zones;
  }

  getClientMenu() {
    return this.clientMenu;
  }

  getDefaultClientPath() {
    let out = config.defaultClientPath;
    const menu = this.getClientMenu();
    if (!menu) {
      return out;
    }
    if (!menu.find((el) => el.link === out)) {
      out = menu[0].link;
    }
    return out;
  }

  /**
   * get user
   */
  async getUser() {
    const clientId = this.getClientId();
    if (!clientId) {
      this.logout();
      return;
      //store.dispatch(userActions.setClient(result))
      //throw new Error('Client id is not exists');
    }
    const result = await api.call('clients.view', { id: clientId }).catch((error) => {
      console.log('🚀 ~ result ~ error', error);
    });
    store.dispatch(userActions.setClient(result));
  }

  /**
   * get additional data from api after user login
   */
  async getAdditionalInfo() {
    // dictionaries
    await this.getDictionaries();

    if (this.state.admin) {
      store.dispatch(userActions.setClient({ clientId: -1, admin: true }));
      return;
    }

    // user
    this.getUser();
  }

  isLogined() {
    return this.logined;
  }

  getSettings = () => {
    return this.settings;
  };

  getZones = () => {
    return this.zones;
  };

  isPageActive = (test) => {
    console.log('🚀 ~ this.rights:', this.rights);
    return this.rights[test];
  };
}

export default new UserService();
